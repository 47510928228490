import React from 'react';
import styled from 'styled-components';
import Items from './Items';

const GarageItems = ({ items }) => {
  return (
    <Container>
      <div className="card-content">
        <div className="card-subtitle">
          <h3 className="bedrooms">Items:</h3>
        </div>
        <Items items={items} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .img-container {
    width: 100%;
  }
  .card-img {
    width: 100%;
  }
  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-title {
    text-transform: uppercase;
  }
  .card-subtitle {
    display: flex;
    padding: 0 0 0.5rem;
  }
  .bedrooms,
  .bathrooms {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .bedrooms {
    padding: 1rem 0.5rem 0 0;
  }
  .bathrooms {
    padding: 1rem 0 0 0.5rem;
  }
  .card-description {
    padding: 0 0 2rem 2rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 2rem;

    .card-subtitle {
      display: flex;
      padding: 1rem 0 0.5rem;
    }
    .card-description {
      width: 70%;

      padding-left: 2rem;
    }
  }
`;

export default GarageItems;
