import React from 'react';
import Title from '../components/Title';
import Layout from '../components/Layout';
import styled from 'styled-components';
import GarageSale from '../components/GarageSale';
import { graphql, useStaticQuery } from 'gatsby';
import '../assets/css/index.css';

const query = graphql`
  {
    allContentfulGarageSale(sort: { fields: updatedAt, order: ASC }) {
      nodes {
        id
        title
        description {
          content
        }
        url
        featured
      }
    }
  }
`;

const Garage = () => {
  const {
    allContentfulGarageSale: { nodes: garage_sale },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Container className="section" id="properties">
        <Title title="garage sale" />
        <div className="section-center">
          <GarageSale garage_sale={garage_sale} />
        </div>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  margin-bottom: 4rem;

  .section-center {
    flex-direction: column;
  }
  .subtitle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Garage;
