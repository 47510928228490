import React from 'react';
import styled from 'styled-components';
import GarageItems from './GarageItems';
import GarageTitle from './GarageTitle';

const GarageSale = ({ garage_sale }) => {
  return (
    <>
      {garage_sale.map((items) => {
        const { id, title, description, url } = items;
        return (
          <Container key={id} className="services">
            <GarageTitle title={title} url={url} />
            <GarageItems items={description} />
          </Container>
        );
      })}
    </>
  );
};

const Container = styled.div``;

export default GarageSale;
