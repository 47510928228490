import React from 'react';

const GarageTitle = ({ title, url }) => {
  return (
    <>
      <a href={url} target="_blank" alt="directions" rel="noreferrer">
        <h3 className="services-title">{title}</h3>
      </a>
      <hr />
    </>
  );
};

export default GarageTitle;
