import React from 'react';
import styled from 'styled-components';

const Items = ({ items }) => {
  return (
    <Container>
      {items.map((item, index) => {
        const { content } = item;
        return (
          <li className="card-list" key={index}>
            {content}
          </li>
        );
      })}
    </Container>
  );
};

const Container = styled.ul`
  padding: 0 0 2rem 2rem;

  @media (min-width: 800px) {
    width: 70%;
    padding-left: 2rem;
  }
`;

export default Items;
